import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { useMobile } from "../../../utils/use-series";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../components/_index";
import "../../../assets/_sass/page/recruit/common.scss";
import "../../../assets/_sass/page/recruit/interview.scss";
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "トップメッセージ",
    content: "Top Message",
    cla_left: "message_left",
    cla_right: "message_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "トップメッセージ",
      src: "/recruit/message/",
    },
  ];

  const picData = {
    img: {
      src: `/assets/images/recruit/message/message03${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "企業文化や社風はいかがでしょうか",
    paragraph: [
      {
        title:
          "かつてのフルサービス型ホテルは上司や先輩の上意下達、つまりトップダウンの文化が根付いていました。技術や知識の継承には適しているかもしれませんが、一方で若手による新しいアイデアが生まれにくいともいえます。",
      },
      {
        title:
          "そんな中、当社のスタッフには若年層も増え、組織の変革も始まっています。人財育成方針に定めた「あたたかくて安心できる職場体温」「あふれる学びの場」「誰もがキーパーソン」「何かやってくれる、何か変えてくれると思われる“人”と“チーム”」「認める文化・褒める文化」が浸透し、各自の心理的安全性も高いと思われます。",
      },
    ],

    subcontent:
      "結果として、特に大規模イベントが行われる際には「for the guest」の意識の下に全員が一丸となって臨みます。最近も営業担当者が記念イベントを受注し、調理や料飲部門にも趣旨を説明して、あわただしい中で周到に準備を進めていました。当日は宿泊部門も応援に駆けつけ、力を合わせて見事に何百人ものゲストを楽しませていましたね。主催者さまからも喜びの声を頂きました。",

      positionSp: true,
  };

  const wordFullData = {
    title: "自社の強みや特長をどのようにお考えですか",
    paragraph: [
      {
        title:
          "フルサービス型のホテルを発祥とし、宿泊のみならず料飲や宴会、婚礼といったサービスを幅広く提供できる点でしょう。",
      },
      {
        title:
          "各部門のプロフェッショナルがホテルの“顔”としてお客さまに向き合う中、それぞれのノウハウを集約させた宿泊主体型の「THE」シリーズも展開し、国内市場における独自のポジションを築けたことは大きな強みです。",
      },
      {
        title:
          "もちろん、ここまで成長できたのは「三菱地所」の信用力や開発力、さらに「三菱地所設計」「メック・デザイン・インターナショナル」の設計力やデザイン力のおかげです。やはり「三菱地所グループ」の支援を受けられるのは唯一無二のメリットですね。",
      },
    ],
  };
  const wordFullData1 = {
    title: "今後の方向性や成長戦略をお聞かせください",
    paragraph: [
      {
        title:
          "新たなマスターブランドステートメントをお客さまに訴求し、浸透させるとともに、サブブランドステートメントに分類される「THE」シリーズの出店戦略を強化する予定です。",
      },
      {
        title:
          "当社は現在全国に23施設を展開しており、大都市圏への出店はほぼ完了しました。一方で、日本人のみならず外国人のお客さまも都市部から地方を目指すようになり、よりローカルで特別な体験を求められています。さらにコロナ禍を経て、皆さまの利用目的もビジネスから観光にシフトしつつあります。こうした背景から、例えば「温泉」をキーワードにその地域ならではのリソースを生かした戦略なども視野に入れていきたいですし、海外への進出の可能性についても検討したいと考えています。",
      },
      {
        title:
          "加えて、既存施設のリニューアルを進め、商品価値を高めることでさらなる知名度アップを図り業績向上を目指します。",
      },
      {
        title:
          "特に「横浜ロイヤルパークホテル」は2024年度末に一度休館し、一定期間大規模改修に入ります。より魅力的に生まれ変わるのを今から楽しみにしています。",
      },
    ],
  };
  const wordFullData3 = {
    title: "働く環境づくりにおいて重視する点は何ですか",
    paragraph: [
      {
        title:
          "2030年までの長期経営計画では「ブランドの体現と浸透」と「人財エンゲージメントの向上による持続的な収益向上の実現」を最重点戦略に掲げ、全施策の基盤としています。そして得られた利潤を人財に還元する継続的なサイクルの確立も目標とします。",
      },
      {
        title:
          "利潤が生まれる好材料として、現在はインバウンドなどの追い風を受けてホテルの稼働率が高く推移している点が挙げられます。また、原価高騰を受け宿泊料を値上げすることにもご理解いただいていると感じます。",
      },
      {
        title:
          "収益が増えたらスタッフの待遇面をより改善するとともに、福利厚生の拡充や、新しいチャレンジを支援する制度も整えていきたいものです。長らくホテル業界では待遇改善の必要性が叫ばれてきましたが、今こそ急務であると認識しています。やはりお客さまの安全安心のためには、スタッフに安全安心な職場環境を用意することが必須だと考えます。",
      },
    ],
  };
  const wordFullData4 = {
    title: "「健康経営宣言」や「女性活躍」について教えてください",
    paragraph: [
      {
        title:
          "2024年4月に「健康経営宣言」を策定しました。上質かつ高度なサービスの提供を実現するために、まずはスタッフが身体的・精神的に健康で充実した生活を送り、仕事とプライベートの調和を実現できるようサポートをしています。具体的には、健康促進プログラムの提供や相談窓口の周知、運動やストレス管理に焦点を当てたセミナーの開催などをおこなっています。",
      },
      {
        title:
          "また、女性スタッフも多く活躍する当社では、彼女たちがチャレンジできる環境や仕組みを整えるべく、女性管理職や育休から復職したスタッフ、若年層のスタッフなどを集め、仕事とプライベートの両立やキャリアをテーマに座談会を開いたりしています。同じように育休を取得した男性社員同士や、これから育休の取得を予定しているスタッフとその上司の対話なども社内イベントとして開催しました。今後は介護と仕事の両立などもテーマに、社内の意見を広く集める予定です。",
      },
    ],
  };
  const wordFullData5 = {
    title: "ホテル業界の現状や今後のトレンド、課題をお聞かせください",
    paragraph: [
      {
        title:
          "旺盛なインバウンド需要を背景に非常に高い稼働率が続いていますが、今後はホテル間の競争が激化するでしょう。特に外資系ホテルチェーンの日本進出が加速し、すでに人財の争奪戦も始まっています。",
      },
      {
        title:
          "また、社会環境は常に変化するため、インバウンドの恩恵をいつまで受けられるか分かりません。その中でお客さまにも働く皆さまにも選ばれるホテルになるには、やはり「ブランドの体現と浸透」「人財エンゲージメントの向上による持続的な収益向上の実現」「人財への還元」というサイクルを回していくのがベストだと思います。ブランド戦略においても多様な価値観に応えられるホテルを開業・運営していきたいですね。",
      },
      {
        title:
          "なお、外資系の進出は脅威ですが、該当する国の方が安心して来日するきっかけにもなります。強力な会員組織を持つホテルチェーンも多く、インバウンドはさらに盛り上がるかもしれません。外資系の旅行代理店によると、日本滞在中に日系のホテルも利用したいとの要望も多いようで、ここにも私たちに提供できる価値があると考えています。",
      },
      {
        title:
          "対して気になるのは日本人の活力の低下です。当グループの利用客も外国人の方が多く、その他のデータを見ても外国人旅行客数の伸びに対して日本人旅行客数は伸び悩んでいるように思います。",
      },
      {
        title:
          "やはり旅先で出会う人、景色、料理などに感動していただきたく、当社も機会の創出に努めてまいります。足元だけを見ると「いかに海外のお客さまをもてなすか」にフォーカスしがちですが、日本のお客さまに喜ばれるポイントも引き続き抑えていきます。",
      },
    ],
  };
  const wordFullData6 = {
    title: "ホテル業界で働く上で、最も大切にしている価値観は何ですか",
    paragraph: [
      {
        title:
          "お客さまに安全や安心を提供するには、前提としてスタッフの安全や安心を確保する必要があります。心穏やかに業務に専念できる環境を整えることが経営陣の大きな責務だと考えています。",
      },
      {
        title:
          "当社の代表に就任後は人事部門ともよく話し、先ほど挙げた「人財グランプリ」もフォローしてきました。また「一人ではなくチームで成果を出す」という発想が広まるよう、事あるごとに発信しています。",
      },
    ],
  };
  const wordFullData7 = {
    title: "新入社員に期待することを教えてください",
    paragraph: [
      {
        title:
          "ホテルがお客さまにとって「心休まる、安全安心を提供する場であること」をまず理解してください。その上で先ほど述べた「自ら考え行動できる」という能力を発揮してほしいですね。",
      },
      {
        title:
          "また、皆さんの「笑顔と挨拶と気配り」は、お客さまはもちろん先輩や仲間、そして、やはりホテル運営に携わる方々にも向けてくれたらと思います。",
      },
    ],
  };
  const picData2 = {
    img: {
      src: `/assets/images/recruit/message/message04${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "差別化を図るための取り組みを教えてください",
    paragraph: [
      {
        title:
          "例えばグループを挙げてSDGs（持続可能な開発目標）に取り組んでいるほか、ホテルごとに地域との共生に臨んでいます。",
      },

      {
        title:
          "具体的には植物由来の生分解性ストローの導入、客室アメニティのバイオマス化、お客さまの要望に合わせたリネン類の交換をはじめ、調理部でもフードロスを減らそうとする意識が非常に高いですね。",
      },
      {
        title:
          "規格外で販売できない果物を引き取ってアイスキャンディーを作り提供しているホテルもありますし、2021年からは皇居外苑濠で刈り取った水草のヒシを堆肥化し、山梨県・北杜市の農家に玉ねぎやトウモロコシを育てていただき、それをスープにしてホテルで提供する活動も行っています。",
      },
    ],
    position: true,
  };
  const picData3 = {
    img: {
       src: `/assets/images/recruit/message/message05${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title:
      "社員間のコミュニケーションやチームワークに対する考えをお聞かせください",
    paragraph: [
      {
        title:
          "以前はグループ内の各ホテルを異なる会社が運営していたため、人財交流やチェーンの一体感がやや希薄でした。しかし、これらは「三菱地所ホテルズ＆リゾーツ」への統合で解消された上、各所のエキスパートが集まってさまざまな地域のホテル開発などを進めるようになりました。ブランドステートメントの確立と併せ、当社の求心力はますます高まると期待しています",
      },
      {
        title:
          "また、優れた業績や貢献を称える社内行事「人財グランプリ」をはじめ各職場単位での表彰制度も整い、本社と各ホテルが一体となってスタッフのモチベーションを高めようと工夫しています。",
      },
      {
        title:
          "一例として「ロイヤルパークホテル（東京・日本橋）」では、夏場に開催されるパティシエたちのコンテストの結果を踏まえ、クリスマス期間に販売される特別なスイーツが決定します。若手を中心に20人ほどがケーキなどの素材や見せ方、味を自ら考え、先輩のサポートを受けながら上位入賞を目指します。完成に向かうプロセスは各自のやる気を喚起させ、チーム力を問われる場としても非常に盛り上がる社内イベントになっています。",
      },
    ],
    positionSp: true,
  };
  const picData4 = {
    img: {
       src: `/assets/images/recruit/message/message06${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "どのようなキャリアを築いてこられましたか",
    paragraph: [
      {
        title:
          "横浜の大学に通っていたため「三菱地所」が横浜みなとみらい21の大開発を進めているのは学生時代から知っていました。事業のスケールに魅力を感じ1989年に同社に新卒で入社して、名古屋支店でキャリアをスタートさせています。",
      },
      {
        title:
          "その後は名古屋と東京で主力事業であるオフィスビルの運営管理に従事し、2007年からの4年間は当社の前身である「ロイヤルパークホテルズアンドリゾーツ」に出向しました。そこで「THE」シリーズの開発に携わり、ゼロから組織づくりに着手したことは貴重な財産になっています。",
      },
      {
        title:
          "さらに現在の「ザ ロイヤルパークホテル 福岡」「ザ ロイヤルパークホテル 京都三条」「ザ ロイヤルパーク キャンバス 名古屋」の開業準備にも携わりました。中でも福岡の案件では土地のオーナーさまにホテルを建てていただき、弊社で借りるモデルを２年がかりで実現させました。この一件が京都三条や名古屋での開業にもつながったので深く思い出に残っています。",
      },
      {
        title:
          "やがて東京・丸の内エリアの再開発などを経て2023年に「丸ノ内ホテル」の社長に就任しました。そして2024年4月から「三菱地所ホテルズ＆リゾーツ」の取締役社長を拝命しています。",
      },
    ],
    position: true,
  };
  const picData5 = {
    img: {
       src: `/assets/images/recruit/message/message07${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "ホテル業界で働く魅力や挑戦は何ですか",
    paragraph: [
      {
        title:
          "私たちの資産、財産、資源は「人」です。そして一人一人が自らの行動を考え、いかにチームとして動かしていくのかが問われるようになりました。",
      },
      {
        title:
          "世界情勢は目まぐるしく変化し、日本では夏場になると線状降水帯や台風に見舞われます。するとお客さまはホテルに到着できなかったり、あるいは延泊を余儀なくされたりしますよね。予定通りに動けない方々を前に何ができるでしょうか。あるいは日々の小さなお困り事を見逃さないためには何に目を向ければいいのでしょうか。",
      },
      {
        title:
          "企画や開発部門でも、お客さまの中長期的なニーズを把握してサービスや施設に実現できるよう落とし込みます。いずれも満足度を高めるには想像力、発想力、瞬発力を駆使したチャレンジが不可欠です。答えの無い世界に挑む魅力と、その答えをお客さまのダイレクトな笑顔やコメントから受け取れる喜びがこの仕事の醍醐味だと思います。",
      },
    ],
    positionSp: true,
  };
  const picData6 = {
    img: {
       src: `/assets/images/recruit/message/message08${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "どのような方を迎えたいとお考えですか",
    paragraph: [
      {
        title:
          "お客さまに三菱地所ホテルズ＆リゾーツを選んでいただこうと自ら考え行動できる方が理想です。自身の意志を実現するために一人で努力するのではなく、周囲を巻き込みながら目標を達成する力が備わっているとより良いでしょう。とはいえ、最初から完璧を求めてはいません。",
      },
      {
        title:
          "分かりやすく説明するなら「柔軟に吸収できる力を持った人財」でしょう。先輩や上長に見守られながら多くの知見を自分のものとし、成長できる方を歓迎します。",
      },
      {
        title:
          "これから当社を目指す方々は、学生時代に多大なコロナの影響を受けたことと思います。マスク越しの会話、オンライン授業、食事の際には黙食が推奨されるなど、友人や教師との関係性を築くのに戸惑った方もいるかもしれません。",
      },
      {
        title:
          "一方で社会に出たら、特に私たちの職場ではコミュニケーションの連続です。お客さまやスタッフとはもちろん、ホテル業務を支えてくださる社外の方とも接点を持ち、積極的に交流して、何かを吸収してほしいですね。",
      },
    ],
    position: true,
  };
  const picData7 = {
    img: {
       src: `/assets/images/recruit/message/message09${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "最後に、当社への応募を考えている方々にメッセージをお願いします",
    paragraph: [
      {
        title:
          "「人財」を資本とするホテル運営会社の当社で、ご自身の想像力、企画力、交渉力を試しながら一緒に伸ばしていきましょう。コロナ禍で学生時代に十分なコミュニケーションを取れなかった方も、入社後のリアルな接客や職場の人間関係を通じ、自分らしい話し方や笑い方、相手の真意の見抜き方といったペースがつかめるようになるはずです。",
      },
      {
        title:
          "そして機会を見つけ、ぜひ私とも話しましょう。当社に就職した動機、会話や人を喜ばせるのが好きな理由など、皆さまの思いを聞かせてください。私は一人一人の夢がかなうよう、これからも社内の風土や育成制度を整え、働きやすい職場づくりに努めていきます。",
      },
    ],
    positionSp: true,
  };


  const picData8 = {
    img: {
      src: `/assets/images/recruit/message/message02${isSp ? "_sp" : ""
        }.png`,
      alt: "",
    },
    title: "まずは「三菱地所ホテルズ＆リゾーツ」のビジョンやバリューを教えてください",
    paragraph: [
      {
        title:
          "「三菱地所ホテルズ＆リゾーツ」としてサービスの統一化を図ろうと、2024年に改めてマスターブランドステートメントを確立させました。併せてビジョン＆クレド、バリュー、サービススタンダードの3つを制定しています。",
      },
      {
        title:
          "まずビジョン＆クレドとして「幸せのピースになる」を掲げました。お客さまの人生をジグソーパズルに見立て、私たちのホテルでのひとときが幸せの完成に向けた一片になるよう願いを込めてのことです。",
      },
      {
        title:
          "また、バリューは「街と、もてなす。」とし、提供するサービスがホテルの中だけで完結するのではなく、街との一体化を目指していることを表現しました。続けてサービススタンダードは、お客さまに寄り添った過不足の無い接客を大切にしようと「心地よい加減」に決めています。",
      },
      {
        title:
          "いずれも「三菱地所グループ」のブランドスローガンである「人を、想う力。街を、想う力。――私たち三菱地所グループは、チャレンジを続けます。」ならびに基本使命の「私たちはまちづくりを通じて社会に貢献します」に協奏しているのがお分かりかと思います。",
      },
    ],
    position: true,
  };

  return (
    <LayoutSl isKv={false}>
      <SEO2 title={frontmatter?.title} description={frontmatter?.description} isTop={false}/>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main top-message-article">
              <Vertical data={verticalData}/>
              <div className="container_right top-message-container-right">
                <AssetImage
                  src={`/assets/images/recruit/message/message${isSp ? "_sp" : ""}.png`}
                  alt=""
                  loading="lazy"
                />
                <p className="m_title_message">
                  求めているのは知見を吸収する姿勢と感謝の心を持つ人財。
                  <br />
                  答えの無い世界に挑み、お客さまの笑顔に答えを見いだす
                  <br />
                  喜びをご一緒に
                </p>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main new_main_sp">
              <WordPic data={picData8} exClass="container_main_message_bisect2" exRightClass="message-p8-p" bisectRightClass="bisect_right_fullImg60" />
              <WordFull data={wordFullData} exClass="container_main_message_full2" />
              <WordPic data={picData} exClass="container_message_picture_same container_main_message_bisect2 " exRightClass="message-p0-p" bisectRightClass="bisect_right_fullImg100" exSubClass="container_main_message_bisect2 mt--5-sp"/>
              <WordFull data={wordFullData1}  exClass="container_main_message_full2 mt--5-sp" />
              <WordPic data={picData2} exClass="container_message_picture_same  container_main_message_bisect2" />
              <WordFull data={wordFullData3} exClass="container_main_message_full2" />
              <WordPic data={picData3} exClass="reverse-container_main_message_bisect message-p3-m container_main_message_bisect2" exRightClass="small-right-pic bisect_right2" />
              <WordFull data={wordFullData4} exClass="container_main_message_full2" exClass_big="mt-5-sp "   />
              <WordPic data={picData4} exClass="container_main_message_bisect2" bisectRightClass="bisect_right_fullImg60" />
              <WordFull data={wordFullData5} exClass="container_main_message_full2" />
              <WordPic data={picData5} exClass="container_message_picture_same  container_main_message_bisect2" bisectRightClass="bisect_right_fullImg100" />
              <WordFull data={wordFullData6}  exClass=" container_main_message_full2" exClass_big="mt-5-sp " />
              <WordPic data={picData6} exClass="container_main_message_bisect2" exRightClass="message-p6-p " bisectRightClass="bisect_right_fullImg60" />
              <WordFull data={wordFullData7} exClass="m container_main_message_full2"/>
              <WordPic data={picData7} exClass="container_message_picture_same  container_main_message_bisect2" />

              <div className="message_bottom pb-10-sp">
                <div>取締役社長</div>
                {/* <img src="/assets/images/recruit/message/message10.png"></img> */}
                {/* <img src={"/assets/images/recruit/message/message09${isSp ? "_sp" : ""}.png"}></img> */}
                <img src={`/assets/images/recruit/message/message10${isSp ? "_sp" : ""}.png`} alt="Dynamic Image" />
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
